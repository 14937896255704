import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import PinPizza from "../assets/icons/Pin-pizza-3.svg"; // Importez l'image

function ChangeView({ center }) {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
  }, [center, map]);
  return null;
}

export default function Map({ toggle }) {
  const [center, setCenter] = useState([
    47.51876706551147, -0.4726950103198834,
  ]);
  const phoneNumber = !toggle ? "02 41 95 40 53" : "02 41 76 10 82";
  const position = !toggle
    ? [47.51876706551147, -0.4726950103198834]
    : [47.57344831301674, -0.3552122920647829];

  const positions = [
    { position: [47.51876706551147, -0.4726950103198834] },
    { position: [47.57344831301674, -0.3552122920647829] },
  ];

  // Créez l'instance de l'icône personnalisée avec l'image importée
  const customIcon = new L.Icon({
    iconUrl: PinPizza, // Utilisez l'image importée
    iconSize: [75, 75],
    iconAnchor: [30, 80],
    popupAnchor: [1, -34],
    shadowSize: [25, 25],
    shadowAnchor: [12, 12],
  });

  useEffect(() => {
    setCenter(position);
  }, [toggle]);

  return (
    <>
      <h2 id="mapTitle" className="sr-only">
        Carte des restaurants Pizza Cook
      </h2>
      <div
        aria-labelledby="mapTitle"
        role="region"
        aria-label="Carte des restaurants Pizza Cook"
      >
        <MapContainer
          center={center}
          zoom={17}
          key={toggle} // Ajoutez une clé ici pour forcer le re-rendu de la carte
        >
          <ChangeView center={center} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {positions.map((pos, index) => (
            <Marker
              key={index}
              position={pos.position}
              icon={customIcon}
              eventHandlers={{
                mouseover: (e) => e.target.openPopup(),
                mouseout: (e) => e.target.closePopup(),
              }}
            >
              <Popup>
                <h3 className="uppercase">Pizza Cook</h3>
                <div className="flex flex-col gap-0.5 text-base-100">
                  {!toggle ? (
                    <p>16 rue Emmanuel Voisin</p>
                  ) : (
                    <p>58 rue Nationale</p>
                  )}
                  {!toggle ? (
                    <p>49480 Saint-Sylvain-D'Anjou</p>
                  ) : (
                    <p>49140 Seiches-Sur-Le-Loir</p>
                  )}
                  <p>{phoneNumber}</p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </>
  );
}
