import MainBtn from "../buttons/MainBtn";

export default function ContactSection({ onClick }) {
  return (
    <section className="contact-section flex justify-center">
      <div className="contact-container flex flex-col items-center text-center text-white">
        <h2>Pour toute question ou demande spécifique</h2>
        <MainBtn value="Contactez-nous" onClick={onClick} name="contact" />
      </div>
    </section>
  );
}
