import TroisFromagesPng from "../../assets/img/pizza-png/3-fromages.png";
import BoursinPng from "../../assets/img/pizza-png/boursin.png";
import CocottePng from "../../assets/img/pizza-png/cocotte.png";
import IndiennePng from "../../assets/img/pizza-png/indienne.png";
import LoisPng from "../../assets/img/pizza-png/lois.png";
import MargheritaPng from "../../assets/img/pizza-png/margherita.png";
import NormandePng from "../../assets/img/pizza-png/normande.png";
import PestoPng from "../../assets/img/pizza-png/pesto.png";
import ReginaPng from "../../assets/img/pizza-png/regina.png";
import RillaudPng from "../../assets/img/pizza-png/rillaud.png";
import SaumonPng from "../../assets/img/pizza-png/saumon.png";
import SymonPng from "../../assets/img/pizza-png/symon.png";
import TexPng from "../../assets/img/pizza-png/tex.png";
import VictorPng from "../../assets/img/pizza-png/victor.png";
import VegetariennePng from "../../assets/img/pizza-png/vegetarienne.png";
import ForestierePng from "../../assets/img/pizza-png/forestiere.png";
import CapriPng from "../../assets/img/pizza-png/capri.png";
import ChorizoPng from "../../assets/img/pizza-png/chorizo.png";
import KebabPng from "../../assets/img/pizza-png/kebab.png";
import TartiflettePng from "../../assets/img/pizza-png/tartiflette.png";
import SavoyardePng from "../../assets/img/pizza-png/savoyarde.png";
import RaclettePng from "../../assets/img/pizza-png/raclette.png";
import FullOptionsPng from "../../assets/img/pizza-png/full-options.png";
import QuatreFromagesPng from "../../assets/img/pizza-png/4-fromages.png";
import AmauryPng from "../../assets/img/pizza-png/amaury.png";
import GauthierPng from "../../assets/img/pizza-png/gauthier.png";
import AnanasPng from "../../assets/img/pizza-png/ananas.png";
import ArthurPng from "../../assets/img/pizza-png/arthur.png";
import BarcelonaPng from "../../assets/img/pizza-png/barcelona.png";
import BurgerPng from "../../assets/img/pizza-png/burger.png";
import LorrainePng from "../../assets/img/pizza-png/lorraine.png";

import TroisFromagesWebp from "../../assets/img/pizza-webp/3-fromages.webp";
import BoursinWebp from "../../assets/img/pizza-webp/boursin.webp";
import CocotteWebp from "../../assets/img/pizza-webp/cocotte.webp";
import IndienneWebp from "../../assets/img/pizza-webp/indienne.webp";
import LoisWebp from "../../assets/img/pizza-webp/lois.webp";
import MargheritaWebp from "../../assets/img/pizza-webp/margherita.webp";
import NormandeWebp from "../../assets/img/pizza-webp/normande.webp";
import PestoWebp from "../../assets/img/pizza-webp/pesto.webp";
import ReginaWebp from "../../assets/img/pizza-webp/regina.webp";
import RillaudWebp from "../../assets/img/pizza-webp/rillaud.webp";
import SaumonWebp from "../../assets/img/pizza-webp/saumon.webp";
import SymonWebp from "../../assets/img/pizza-webp/symon.webp";
import TexWebp from "../../assets/img/pizza-webp/tex.webp";
import VictorWebp from "../../assets/img/pizza-webp/victor.webp";
import VegetarienneWebp from "../../assets/img/pizza-webp/vegetarienne.webp";
import ForestiereWebp from "../../assets/img/pizza-webp/forestiere.webp";
import CapriWebp from "../../assets/img/pizza-webp/capri.webp";
import ChorizoWebp from "../../assets/img/pizza-webp/chorizo.webp";
import KebabWebp from "../../assets/img/pizza-webp/kebab.webp";
import TartifletteWebp from "../../assets/img/pizza-webp/tartiflette.webp";
import SavoyardeWebp from "../../assets/img/pizza-webp/savoyarde.webp";
import RacletteWebp from "../../assets/img/pizza-webp/raclette.webp";
import FullOptionsWebp from "../../assets/img/pizza-webp/full-options.webp";
import QuatreFromagesWebp from "../../assets/img/pizza-webp/4-fromages.webp";
import AmauryWebp from "../../assets/img/pizza-webp/amaury.webp";
import GauthierWebp from "../../assets/img/pizza-webp/gauthier.webp";
import AnanasWebp from "../../assets/img/pizza-webp/ananas.webp";
import ArthurWebp from "../../assets/img/pizza-webp/arthur.webp";
import BarcelonaWebp from "../../assets/img/pizza-webp/barcelona.webp";
import BurgerWebp from "../../assets/img/pizza-webp/burger.webp";
import LorraineWebp from "../../assets/img/pizza-webp/lorraine.webp";






export const PizzasList = [
  [
    {
      id: 1,
      slug: "margherita",
      name: "Margherita",
      priceMin: "10.50",
      priceMax: "16.90",
      description: "Tomate, mozzarella, jambon, origan",
      imagePng: MargheritaPng,
      imageWebp: MargheritaWebp,
      category: "adulte",
    },
    {
      id: 2,
      slug: "cocotte",
      name: "Cocotte",
      priceMin: "10.50",
      priceMax: "16.90",
      description:
        "Tomate, mozzarella, jambon, jaune d'œufs, herbes de Provence",
      imagePng: CocottePng,
      imageWebp: CocotteWebp,
      category: "adulte",
    },
    {
      id: 3,
      slug: "boursin",
      name: "Boursin",
      priceMin: "11.50",
      priceMax: "17.90",
      description: "Crème fraîche, mozzarella, jambon blanc, sauce Boursin",
      imagePng: BoursinPng,
      imageWebp: BoursinWebp,
      category: "adulte",
    },
    {
      id: 4,
      slug: "3-fromages",
      name: "3 Fromages",
      priceMin: "10.50",
      priceMax: "16.90",
      description: "Tomate, mozzarella, chèvre, fromage de Savoie",
      imagePng: TroisFromagesPng,
      imageWebp: TroisFromagesWebp,
      category: "adulte",
    },
    {
      id: 5,
      slug: "vegetarienne",
      name: "Végétarienne",
      priceMin: "11.50",
      priceMax: "17.90",
      description: "Tomate, mozzarella, champignons, poivrons, oignons, olives",
      imagePng: VegetariennePng,
      imageWebp: VegetarienneWebp,
      category: "adulte",
    },
    {
      id: 6,
      slug: "4-fromages",
      name: "4 Fromages",
      priceMin: "11.50",
      priceMax: "17.90",
      description:
        "Tomate, mozzarella, chèvre, fromage de Savoie, bleu d'auvergne",
      imagePng: QuatreFromagesPng,
      imageWebp: QuatreFromagesWebp,
      category: "adulte",
    },
    {
      id: 7,
      slug: "regina",
      name: "Regina",
      priceMin: "13.50",
      priceMax: "19.90",
      description:
        "Tomate, mozzarella, jambon, champignons, oignons, olives, roquette",
      imagePng: ReginaPng,
      imageWebp: ReginaWebp,
      category: "adulte",
    },
    {
      id: 8,
      slug: "tex",
      name: "Tex",
      priceMin: "13.50",
      priceMax: "19.90",
      description: "Tomate, mozzarella, poulet, poivrons, oignons",
      imagePng: TexPng,
      imageWebp: TexWebp,
      category: "adulte",
    },
    {
      id: 9,
      slug: "barcelona",
      name: "Barcelona",
      priceMin: "13.50",
      priceMax: "19.90",
      description: "Tomate, mozzarella, jambon cru, chorizo, olives",
      imagePng: BarcelonaPng,
      imageWebp: BarcelonaWebp,
      category: "adulte",
    },
    {
      id: 10,
      slug: "forestiere",
      name: "Forestière",
      priceMin: "13.50",
      priceMax: "19.90",
      description: "Tomate, mozzarella, champignons, lardons, crème fraîche",
      imagePng: ForestierePng,
      imageWebp: ForestiereWebp,
      category: "adulte",
    },
    {
      id: 11,
      slug: "lorraine",
      name: "Lorraine",
      priceMin: "13.50",
      priceMax: "19.90",
      description:
        "Crème fraîche, mozzarella, pomme de terre, lardons, oignons",
      imagePng: LorrainePng,
      imageWebp: LorraineWebp,
      category: "adulte",
    },
    {
      id: 12,
      slug: "ananas",
      name: "Ananas",
      priceMin: "13.50",
      priceMax: "19.90",
      description:
        "Tomate, mozzarella, viande au choix : jambon, viande hachée, poulet, lardons",
      imagePng: AnanasPng,
      imageWebp: AnanasWebp,
      category: "adulte",
    },
    {
      id: 13,
      slug: "pesto",
      name: "Pesto",
      priceMin: "11.50",
      priceMax: "17.90",
      description: "Tomate, mozzarella, chèvre, pesto",
      imagePng: PestoPng,
      imageWebp: PestoWebp,
      category: "adulte",
    },
    {
      id: 14,
      slug: "saumon",
      name: "Saumon",
      priceMin: "14.50",
      priceMax: "21.90",
      description: "Tomate, mozzarella, saumon, crème fraîche, ciboulette",
      imagePng: SaumonPng,
      imageWebp: SaumonWebp,
      category: "adulte",
    },
    {
      id: 15,
      slug: "capri",
      name: "Capri",
      priceMin: "13.50",
      priceMax: "19.90",
      description: "Tomate, mozzarella, jambon, chèvre, miel",
      imagePng: CapriPng,
      imageWebp: CapriWebp,
      category: "adulte",
    },
    {
      id: 16,
      slug: "burger",
      name: "Burger",
      priceMin: "14.50",
      priceMax: "21.90",
      description:
        "Tomate, mozzarella, viande hachée, cheddar, sauce barbecue, oignons",
      imagePng: BurgerPng,
      imageWebp: BurgerWebp,
      category: "adulte",
    },
    {
      id: 17,
      slug: "chorizo",
      name: "Chorizo",
      priceMin: "14.50",
      priceMax: "21.90",
      description: "Tomate, mozzarella, boeuf hachée, chorizo, poivrons",
      imagePng: ChorizoPng,
      imageWebp: ChorizoWebp,
      category: "adulte",
    },
    {
      id: 18,
      slug: "kebab",
      name: "Kebab",
      priceMin: "14.50",
      priceMax: "21.90",
      description:
        "Tomate, mozzarella, pomme de terre, viande kebab, sauce blanche",
      imagePng: KebabPng,
      imageWebp: KebabWebp,
      category: "adulte",
    },
    {
      id: 19,
      slug: "indienne",
      name: "Indienne",
      priceMin: "14.50",
      priceMax: "21.90",
      description:
        "Crème fraîche, mozzarella, pomme de terre, poulet, sauce curry",
      imagePng: IndiennePng,
      imageWebp: IndienneWebp,
      category: "adulte",
    },
    {
      id: 20,
      slug: "rillaud",
      name: "Rillaud",
      priceMin: "15.50",
      priceMax: "22.90",
      description:
        "Tomate, mozzarella, pomme de terre, rillauds, crème fraîche, oignons",
      imagePng: RillaudPng,
      imageWebp: RillaudWebp,
      category: "adulte",
    },
    {
      id: 21,
      slug: "tartiflette",
      name: "Tartiflette",
      priceMin: "14.50",
      priceMax: "21.90",
      description:
        "Tomate, mozzarella, pomme de terre, lardons, fromage de Savoie, oignons, crème fraîche",
      imagePng: TartiflettePng,
      imageWebp: TartifletteWebp,
      category: "adulte",
    },
    {
      id: 22,
      slug: "savoyarde",
      name: "Savoyarde",
      priceMin: "14.50",
      priceMax: "21.90",
      description:
        "Tomate, mozzarella, pomme de terre, jambon cru, fromage de Savoie, oignons, crème fraîche",
      imagePng: SavoyardePng,
      imageWebp: SavoyardeWebp,
      category: "adulte",
    },
    {
      id: 23,
      slug: "normande",
      name: "Normande",
      priceMin: "14.50",
      priceMax: "21.90",
      description:
        "Crème fraîche, mozzarella, pomme de terre, lardons, camembert",
      imagePng: NormandePng,
      imageWebp: NormandeWebp,
      category: "adulte",
    },
    {
      id: 24,
      slug: "raclette",
      name: "Raclette",
      priceMin: "15.50",
      priceMax: "22.90",
      description:
        "Crème fraîche, mozzarella, pomme de terre, jambon blanc, jambon cru, raclette",
      imagePng: RaclettePng,
      imageWebp: RacletteWebp,
      category: "adulte",
    },
    {
      id: 25,
      slug: "full-options",
      name: "Full options",
      priceMin: "15.50",
      priceMax: "22.90",
      description:
        "Tomate, mozzarella, pomme de terre, jambon cru, magret, lardons, fromage de Savoie, oignons, crème fraîche",
      imagePng: FullOptionsPng,
      imageWebp: FullOptionsWebp,
      category: "adulte",
    },
  ],
  [
    {
      id: 26,
      slug: "arthur",
      name: "Arthur",
      priceMin: "7.00",
      priceMax: "7.00",
      description: "Crème fraîche, mozzarella, lardons",
      imagePng: ArthurPng,
      imageWebp: ArthurWebp,
      category: "enfant",
    },
    {
      id: 27,
      slug: "victor",
      name: "Victor",
      priceMin: "7.00",
      description: "Tomate, mozzarella, jambon, crème fraîche",
      imagePng: VictorPng,
      imageWebp: VictorWebp,
      category: "enfant",
    },
    {
      id: 28,
      slug: "lois",
      name: "Lois",
      priceMin: "7.00",
      description: "Tomate, mozzarella, poulet, crème fraîche",
      imagePng: LoisPng,
      imageWebp: LoisWebp,
      category: "enfant",
    },
    {
      id: 29,
      slug: "symon",
      name: "Symon",
      priceMin: "7.00",
      description: "Tomate, mozzarella, boeuf hachée, crème fraîche",
      imagePng: SymonPng,
      imageWebp: SymonWebp,
      category: "enfant",
    },
    {
      id: 30,
      slug: "amaury",
      name: "Amaury",
      priceMin: "7.00",
      description: "Tomate, mozzarella, pomme de terre, lardons",
      imagePng: AmauryPng,
      imageWebp: AmauryWebp,
      category: "enfant",
    },
    {
      id: 31,
      slug: "gauthier",
      name: "Gauthier",
      priceMin: "7.00",
      description: "Tomate, mozzarella, reblonchon, chèvre",
      imagePng: GauthierPng,
      imageWebp: GauthierWebp,
      category: "enfant",
    },
  ],
];
