import { useRoutes } from "react-router-dom";

import Contact from "../pages/public/Contact";
import NotFound from "../pages/redirect/NotFound";
import { MainRoutes } from "./MainRoutes";

export default function ThemeRoutes() {
  return useRoutes([
    // { path: "/admin", element: <Navigate to="/admin/board" replace /> },
    MainRoutes,
    { path: "*", element: <NotFound /> },
    { path: "contact", element: <Contact /> },
  ]);
}
