import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import MushroomGrey from "../../assets/img/mushroom-grey.png";
import MushroomGreyWebp from "../../assets/img/mushroom-grey.webp";
import PizzaLettersGrey from "../../assets/img/pizza-letters-grey.png";
import PizzaLettersGreyWebp from "../../assets/img/pizza-letters-grey.webp";
import ScooterGrey from "../../assets/img/scooter-grey.png";
import ScooterGreyWebp from "../../assets/img/scooter-grey.webp";
import WheatGrey from "../../assets/img/wheat-grey.png";
import WheatGreyWebp from "../../assets/img/wheat-grey.webp";
import MainBtn from "../buttons/MainBtn";
import { WidthContext } from "./Providers";

export const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const { width } = useContext(WidthContext);

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      // Log l'erreur dans la console ou effectue d'autres actions nécessaires.
      console.error(error, errorInfo);
    };

    // Attach a global error listener
    const errorListener = (event) => {
      setHasError(true);
      handleError(event.error, event);
    };

    window.addEventListener("error", errorListener);

    // Cleanup the listener on component unmount
    return () => {
      window.removeEventListener("error", errorListener);
    };
  }, []);

  const isProduction = process.env.NODE_ENV === "production";

  if (hasError && isProduction) {
    return (
      <div className="not-found overflow-hidden">
        <picture className="wheat-grey-404">
          <source srcSet={WheatGreyWebp} type="image/webp" />
          <source srcSet={WheatGrey} type="image/png" />
          <img src={WheatGrey} alt="" />
        </picture>
        <picture className="pizza-letters-grey-404">
          <source srcSet={PizzaLettersGreyWebp} type="image/webp" />
          <source srcSet={PizzaLettersGrey} type="image/png" />
          <img src={PizzaLettersGrey} alt="" />
        </picture>
        <div className="flex flex-col items-center justify-center main-error">
          <h1 className="flex items-center justify-center text-base-100 title-error">
            Une erreur est survenue...
          </h1>
          <p className="text-base-100 not-found-content text-center">
            Nous sommes désolés, il semble qu'il y ait eu un problème lors du
            chargement de la page que vous demandez.
          </p>
          <MainBtn
            value="Retour à l'accueil"
            onClick={() => (window.location.href = "/")}
          />
        </div>
        <picture className="mushroom-grey-404">
          <source srcSet={MushroomGreyWebp} type="image/webp" />
          <source srcSet={MushroomGrey} type="image/png" />
          <img src={MushroomGrey} alt="" className="mushroom-grey-404" />
        </picture>
        <motion.picture
          initial={{
            x: width >= 768 ? -600 : -250,
            opacity: 0,
            rotate: -30,
          }}
          whileInView={{
            x: 0,
            opacity: 1,
            rotate: [0, -30, -30, 30, 0],
          }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{
            duration: 3.5,
            ease: "backInOut",
          }}
          className="scooter-grey-404"
        >
          <source srcSet={ScooterGreyWebp} type="image/webp" />
          <source srcSet={ScooterGrey} type="image/png" />
          <img
            src={ScooterGrey}
            alt=""
            loading="lazy"
            className="scooter-grey-404"
          />
        </motion.picture>
      </div>
    );
  }

  return children;
};
