export default function SlidingMessage({ color }) {
  return (
    <section
      className={`sliding-message-section flex items-center justify-center text-white ${
        color === "beige" && "bg-secondary" + " text-base-100"
      }`}
    >
      <p data-text="Fait maison • Farine 100% BIO • Savoir-faire artisanal • Produits frais">
        <span>
          Fait maison • Farine 100% BIO •Savoir-faire artisanal • Produits frais
        </span>
      </p>
    </section>
  );
}
