import {motion} from "framer-motion";
import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import PizzaGrey from "../../assets/img/pizza-grey.png";
import PizzaGreyWebp from "../../assets/img/pizza-grey.webp";
import PizzaSliceGrey from "../../assets/img/pizza-slice-grey.png";
import PizzaSliceGreyWebp from "../../assets/img/pizza-slice-grey.webp";
import PizzaSkillsSmall from "../../assets/img/savoir-faire-pizza.png";
import PizzaSkillsSmallWebp from "../../assets/img/savoir-faire-pizza.webp";
import PizzaSkills from "../../assets/img/savoir-faire-pâte.png";
import PizzaSkillsWebp from "../../assets/img/savoir-faire-pâte.webp";
import TomatoGrey from "../../assets/img/tomato-grey.png";
import TomatoGreyWebp from "../../assets/img/tomato-grey.webp";
import HeroCheese from "../../assets/img/hero/cheese.png"
import HeroCheeseWebp from "../../assets/img/hero/cheese.webp"
import HeroChili from "../../assets/img/hero/chili.png"
import HeroChiliWebp from "../../assets/img/hero/chili.webp"
import HeroFlour from "../../assets/img/hero/flour.png"
import HeroFlourWebp from "../../assets/img/hero/flour.webp"
import HeroGarlic from "../../assets/img/hero/garlic.png"
import HeroGarlicWebp from "../../assets/img/hero/garlic.webp"
import HeroLeaf from "../../assets/img/hero/leaf.png"
import HeroLeafWebp from "../../assets/img/hero/leaf.webp"
import HeroPepper from "../../assets/img/hero/pepper.png"
import HeroPepperWebp from "../../assets/img/hero/pepper.webp"
import HeroOnions from "../../assets/img/hero/onions.png"
import HeroOnionsWebp from "../../assets/img/hero/onions.webp"
import HeroSeeds from "../../assets/img/hero/seeds.png"
import HeroSeedsWebp from "../../assets/img/hero/seeds.webp"
import HeroTomato from "../../assets/img/hero/tomato.png"
import HeroTomatoWebp from "../../assets/img/hero/tomato.webp"
import HeroPizza from "../../assets/img/hero/pizza.png"
import HeroPizzaWebp from "../../assets/img/hero/pizza.webp"
import MainBtn from "../../components/buttons/MainBtn";
import ContactSection from "../../components/sections/ContactSection";
import GroupOrderSection from "../../components/sections/GroupOrderSection";
import PizzaCarrouselSection from "../../components/sections/PizzaCarrouselSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import SlidingMessage from "../../components/sections/SlidingMessage";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function Landing() {
  const navigate = useNavigate();
  const {width} = useContext(WidthContext);
  const {setColor} = useContext(ColorModeContext);

  const handleClick = (ev) => {
    if (ev.target.name === "contact") {
      navigate("/contact");
    } else if (ev.target.name === "group") {
      navigate("/livraison");
    } else if (ev.target.name === "skills") {
      navigate("/pizza-cook");
    } else if (ev.target.name === "menu") {
      navigate("/notre-carte");
    }
  };

  useEffect(() => {
    setColor("black");
  }, []);

  return (
    <>
      <MetaTagsMaster
        title="Accueil"
        description="Découvrez nos pizzerias à Saint-Sylvain-D'Anjou et Seiches-sur-le-Loir. Savourez nos pizzas artisanales, réalisées avec des ingrédients de qualité."
        keywords="pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <div className="main-landing-container">
        <section className="landing-header-section">
          <div className="landing-header-container">
            <h1 className="text-white uppercase text-landing">
              Des pizzas, gourmandes et appétissantes
            </h1>
            <MainBtn value="Voir la carte" onClick={handleClick} name="menu"/>
          </div>
          <div className="grid-img-landing">
            <picture className="hero-pizza">
              <source srcSet={HeroPizza} type="image/png"/>
              <source srcSet={HeroPizzaWebp} type="image/webp"/>
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0,
                  rotate: 360,
                  x: -400
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  rotate: 0,
                  x: 0
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroPizza}
                alt="Une moitié de pizza"
                loading="lazy"
                className="hero-pizza-img"
              />
            </picture>
            <picture className="hero-tomato">
              <source srcSet={HeroTomato} type="image/png"/>
              <source srcSet={HeroTomatoWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut"
                }}
                src={HeroTomato}
                alt="Des tomates"
                loading="lazy"
                className="hero-tomato-img"
              />
            </picture>
            <picture className="hero-seeds">
              <source srcSet={HeroSeeds} type="image/png"/>
              <source srcSet={HeroSeedsWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroSeeds}
                alt="Du poivre"
                loading="lazy"
                className="hero-seeds-img"
              />
            </picture>
            <picture className="hero-leaf">
              <source srcSet={HeroLeaf} type="image/png"/>
              <source srcSet={HeroLeafWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroLeaf}
                alt="Du basilic"
                loading="lazy"
                className="hero-leaf-img"
              />
            </picture>
            <picture className="hero-pepper">
              <source srcSet={HeroPepper} type="image/png"/>
              <source srcSet={HeroPepperWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroPepper}
                alt="Un poivron"
                loading="lazy"
                className="hero-pepper-img"
              />
            </picture>
            <picture className="hero-onions">
              <source srcSet={HeroOnions} type="image/png"/>
              <source srcSet={HeroOnionsWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroOnions}
                alt="Un oignon"
                loading="lazy"
                className="hero-onions-img"
              />
            </picture>
            <picture className="hero-cheese">
              <source srcSet={HeroCheese} type="image/png"/>
              <source srcSet={HeroCheeseWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y: -600,
                  x: -600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroCheese}
                alt="Du fromage"
                loading="lazy"
                className="hero-cheese-img"
              />
            </picture>
            <picture className="hero-chili">
              <source srcSet={HeroChili} type="image/png"/>
              <source srcSet={HeroChiliWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y:-600,
                  x:-600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroChili}
                alt="Un piment"
                loading="lazy"
                className="hero-chili-img"
              />
            </picture>
            <picture className="hero-flour">
              <source srcSet={HeroFlour} type="image/png"/>
              <source srcSet={HeroFlourWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y:-600,
                  x:-600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroFlour}
                alt="De la farine"
                loading="lazy"
                className="hero-flour-img"
              />
            </picture>
            <picture className="hero-garlic">
              <source srcSet={HeroGarlic} type="image/png"/>
              <source srcSet={HeroGarlicWebp} type="image/webp"/>
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                  y:-600,
                  x:-600
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  y: 0,
                  x: 0,
                }}
                transition={{
                  duration: 5,
                  ease: "backOut",
                }}
                src={HeroGarlic}
                alt="De l'ail"
                loading="lazy"
                className="hero-garlic-img"
              />
            </picture>
          </div>
        </section>
        <PizzaCarrouselSection onClick={handleClick}/>
        <section className="savoir-faire-section">
          {width < 1440 && (
            <div className="savoir-faire-img flex pl-[1.875rem] justify-between overflow-hidden">
              <picture className="pizza-sliced-grey">
                <source srcSet={PizzaSliceGreyWebp} type="image/webp"/>
                <source srcSet={PizzaSliceGrey} type="image/png"/>
                <motion.img
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                  }}
                  viewport={{once: true, amount: 0.3}}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={PizzaSliceGrey}
                  alt=""
                  loading="lazy"
                />
              </picture>
              <picture className="pizza-grey">
                <source srcSet={PizzaGreyWebp} type="image/webp"/>
                <source srcSet={PizzaGrey} type="image/png"/>
                <motion.img
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                  }}
                  viewport={{once: true, amount: 0.3}}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={PizzaGrey}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
          )}
          <div className="img-desktop-savoir-faire-container">
            {width >= 1440 && (
              <div className="flex justify-between">
                <picture className="pizza-sliced-grey">
                  <source srcSet={PizzaSliceGreyWebp} type="image/webp"/>
                  <source srcSet={PizzaSliceGrey} type="image/png"/>
                  <motion.img
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{once: true, amount: 0.3}}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    src={PizzaSliceGrey}
                    alt=""
                    loading="lazy"
                  />
                </picture>
                <picture className="tomato-grey">
                  <source srcSet={TomatoGreyWebp} type="image/webp"/>
                  <source srcSet={TomatoGrey} type="image/png"/>
                  <motion.img
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{once: true, amount: 0.3}}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    src={TomatoGrey}
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </div>
            )}
            <div className="flex flex-col savoir-faire-container-content">
              <h2 className="uppercase text-base-100">
                Un savoir-faire artisanal
              </h2>
              <p className="text-base-100">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Corporis culpa cumque deserunt earum est illo incidunt ipsa.
              </p>
              <MainBtn
                value="En savoir plus"
                name="skills"
                onClick={handleClick}
              />
            </div>
            {width >= 1440 && (
              <div className="flex justify-end">
                <picture className="pizza-grey">
                  <source srcSet={PizzaGreyWebp} type="image/webp"/>
                  <source srcSet={PizzaGrey} type="image/png"/>
                  <motion.img
                    initial={{
                      rotate: 0,
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      rotate: 360,
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{once: true, amount: 0.3}}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    src={PizzaGrey}
                    alt=""
                    loading="lazy"
                  />
                </picture>
              </div>
            )}
          </div>
          <div className="savoir-faire-container-img grid grid-cols-1 grid-rows-3">
            <picture className="pizza-skills">
              <source srcSet={PizzaSkillsWebp} type="image/webp"/>
              <source srcSet={PizzaSkills} type="image/png"/>
              <motion.img
                initial={{
                  opacity: 0,
                  x: -100,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                viewport={{once: true, amount: 0.3}}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={PizzaSkills}
                alt="Un pizzaïolo qui pétrit la pâte"
                loading="lazy"
              />
            </picture>
            <picture className="pizza-skills-small h-full z-10">
              <source srcSet={PizzaSkillsSmallWebp} type="image/webp"/>
              <source srcSet={PizzaSkillsSmall} type="image/png"/>
              <motion.img
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                viewport={{once: true, amount: 0.3}}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={PizzaSkillsSmall}
                alt="Une pizza qui sort du four à pain"
                loading="lazy"
              />
            </picture>
          </div>
        </section>
        <SlidingMessage/>
        <PizzeriasSection width={width}/>
        <GroupOrderSection onClick={handleClick} width={width}/>
        <ContactSection onClick={handleClick}/>
      </div>
    </>
  );
}
