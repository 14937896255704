import { motion } from "framer-motion";
import CardMenuHeader from "../../assets/img/card-menu-header.png";
import CardMenuHeaderWebp from "../../assets/img/card-menu-header.webp";
import DefaultPizzaBanner from "../../assets/img/default-pizza-banner.png";
import DefaultPizzaBannerWebp from "../../assets/img/default-pizza-banner.webp";
import Distributor from "../../assets/img/distrib.png";
import DistributorWebp from "../../assets/img/distrib.webp";
import OvenGrey from "../../assets/img/oven-grey.png";
import OvenGreyWebp from "../../assets/img/oven-grey.webp";
import PizzaCookHeader from "../../assets/img/pizza-cook-page-header.png";
import PizzaCookHeaderWebp from "../../assets/img/pizza-cook-page-header.webp";
import MainBtn from "../buttons/MainBtn";

export default function HeaderInfos({
  title,
  description,
  onClick,
  width,
  option,
}) {
  return option === "menu" ? (
    <section
      className={`pizza-cook-header ${
        width < 768 ? "bg-base-100 text-white" : "bg-white text-base-100"
      }`}
    >
      <div className="pizza-cook-header-content flex flex-col">
        <h1 className="uppercase">{title}</h1>
        {width >= 768 && (
          <div className="flex flex-col gap-[32px]">
            <p>{description}</p>
            {width >= 768 && (
              <MainBtn
                value="Parcourir la carte"
                name="show-menu"
                onClick={onClick}
              />
            )}
          </div>
        )}
      </div>
      {width >= 768 && (
        <div className="grid-container grid grid-cols-2 w-full">
          <div className="oven-grey-container place-self-center">
            <picture>
              <source srcSet={OvenGreyWebp} type="image/webp" />
              <source srcSet={OvenGrey} type="image/png" />
              <motion.img
                initial={{
                  scale: 0,
                  opacity: 0,
                }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={OvenGrey}
                alt="Oven Grey"
                className="oven-grey"
                loading="lazy"
              />
            </picture>
          </div>
          <div className="pizza-cook-header-img bg-secondary flex justify-end items-center place-self-end">
            <div className="pizza-cook-header-img bg-secondary flex justify-end items-center">
              <picture>
                <source srcSet={CardMenuHeaderWebp} type="image/webp" />
                <source srcSet={CardMenuHeader} type="image/png" />
                <motion.img
                  initial={{
                    x: 100,
                    opacity: 0,
                  }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                  }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={CardMenuHeader}
                  alt="Une pizza qui sort du four à pain"
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        </div>
      )}
    </section>
  ) : (
    <section className="pizza-cook-header bg-white text-base-100">
      <div className="pizza-cook-header-content flex flex-col">
        <h1 className="uppercase">{title}</h1>
        <p>{description}</p>
      </div>
      <div className="grid-container grid grid-cols-2 w-full">
        <div className="oven-grey-container place-self-center">
          <picture>
            <source srcSet={OvenGreyWebp} type="image/webp" />
            <source srcSet={OvenGrey} type="image/png" />
            <motion.img
              initial={{
                scale: 0,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={OvenGrey}
              alt="Oven Grey"
              className="oven-grey"
              loading="lazy"
            />
          </picture>
        </div>
        <div className="pizza-cook-header-img bg-secondary flex justify-end items-center place-self-end">
          <picture>
            <source
              srcSet={
                option === "pizzacook"
                  ? PizzaCookHeaderWebp
                  : option === "distrib"
                  ? DistributorWebp
                  : option === "group"
                  ? DefaultPizzaBannerWebp
                  : null
              }
              type="image/webp"
            />
            <source
              srcSet={
                option === "pizzacook"
                  ? PizzaCookHeader
                  : option === "distrib"
                  ? Distributor
                  : option === "group"
                  ? DefaultPizzaBanner
                  : null
              }
              type="image/png"
            />
            <motion.img
              initial={{
                x: 100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={
                option === "pizzacook"
                  ? PizzaCookHeader
                  : option === "distrib"
                  ? Distributor
                  : option === "group"
                  ? DefaultPizzaBanner
                  : null
              }
              alt={
                option === "pizzacook"
                  ? "Un pizzaïolo qui pétrit la pâte"
                  : option === "distrib"
                  ? "Un" + " distributeur pizza cook"
                  : option === "group"
                  ? "Une pizza classique"
                  : ""
              }
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </section>
  );
}
