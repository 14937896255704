import { useContext, useEffect } from "react";
import NavBar from "../../components/Navbar";
import ToggleBtn from "../../components/buttons/ToggleBtn";
import EventForm from "../../components/forms/EventForm";
import PublicForm from "../../components/forms/PublicForm";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  ToggleContext,
} from "../../components/utils/Providers";

export default function Contact() {
  const { setColor } = useContext(ColorModeContext);
  const { toggle } = useContext(ToggleContext);

  useEffect(() => {
    setColor("black");
  }, []);

  return (
    <>
      <MetaTagsMaster
        title="Contact"
        description="Une question ou demande spécifique ? Nous serions ravies de répondre à vos questions ou couvrir vos événements."
        keywords="contact, information, événement, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <NavBar />
      <div className="main-contact-container text-white">
        <div className="pb-16">
          <h1 className="uppercase text-white">Contact</h1>
          <p>
            Une question ou demande spécifique ? Nous serions ravies de couvrir
            vos événements.
          </p>
        </div>
        <div className="contact-form">
          <div className={`flex gap-[10px] pb-8 items-center`}>
            <ToggleBtn
              className={
                toggle
                  ? "toggle toggle-input [--tglbg:white] hover:bg-primary bg-primary"
                  : "toggle" +
                    " toggle-input [--tglbg:white] hover:bg-primary bg-base-100"
              }
              ariaLabel={!toggle ? "contact" + " simple" : "contact événement"}
            />
            <p>Besoin de pizza pour votre événement ?</p>
          </div>
          {!toggle ? <PublicForm /> : <EventForm />}
        </div>
      </div>
    </>
  );
}
