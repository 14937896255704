import { useContext } from "react";
import { ToggleContext } from "../utils/Providers";

export default function ToggleBtn({ className, ariaLabel }) {
  const { toggle, setToggle } = useContext(ToggleContext);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <input
      type="checkbox"
      className={className}
      checked={toggle}
      onChange={handleToggle}
      aria-pressed={toggle}
      aria-label={ariaLabel}
    />
  );
}
