import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo-pizzacook.png";
import LogoWebp from "../assets/img/logo-pizzacook.webp";
import BurgerMenu from "./buttons/BurgerMenu";
import { ColorModeContext } from "./utils/Providers";
import {motion} from "framer-motion";

export default function Navbar() {
  const navigate = useNavigate();
  const { color } = useContext(ColorModeContext);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div
      className={`flex justify-between items-center navbar-container ${
        color === "white"
          ? "bg-white"
          : color === "black"
          ? "bg-base-100"
          : "bg-secondary"
      }`}
    >
      <motion.picture
        whileHover={{
          scale: 1.05,
        }}
        transition={{
          duration: 0.5,
          ease: "backInOut",
        }}>
        <source srcSet={LogoWebp} type="image/webp" />
        <source srcSet={Logo} type="image/png" />
        <img
          src={Logo}
          alt="Logo Pizza Cook"
          className="logo-pizza cursor-pointer"
          onClick={handleClick}
          loading="lazy"
        />
      </motion.picture>
      <div className="flex items-center components-container">
        {/*<Cart/>*/}
        <BurgerMenu />
      </div>
    </div>
  );
}
