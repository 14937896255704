import React from "react";
import { createPortal } from "react-dom";

export default function PortalModal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 bg-base-100 bg-opacity-90 z-[1000] flex items-center justify-center p-4">
      <div className="bg-base-100 max-h-[70%] border-2 border-solid border-secondary rounded-lg shadow-xl relative p-6 w-full max-w-2xl pt-[60px]">
        <button
          className="btn btn-square btn-outline absolute top-3 right-3"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("root")
  );
}
