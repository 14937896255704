import { useState } from "react";
import { Link } from "react-router-dom";
import { postContactGeneral } from "../../api/Message";
import PortalModal from "../PortalModal";

export default function PublicForm() {
  const [data, setData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      await postContactGeneral(
        data.lastname,
        data.firstname,
        data.email,
        data.content
      );
      setIsFinish(true);
      setData({ lastname: "", firstname: "", email: "", content: "" });
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isLoading ? (
        <form
          className="flex flex-col"
          autoComplete="on"
          onSubmit={handleSubmit}
        >
          <div className="name-container flex gap-[24px] w-full">
            <div className="flex flex-col gap-[8px] w-full">
              <label htmlFor="lastname">
                Nom <span className="text-primary">*</span>
              </label>
              <input
                required
                type="text"
                name="lastname"
                id="lastname"
                aria-labelledby="lastname"
                autoComplete="lastname"
                onChange={handleChange}
                value={data.lastname}
              />
            </div>
            <div className="flex flex-col gap-[8px] w-full">
              <label htmlFor="name">
                Prénom <span className="text-primary">*</span>
              </label>
              <input
                required
                type="text"
                name="firstname"
                id="firstname"
                aria-labelledby="firstname"
                autoComplete="firstname"
                onChange={handleChange}
                value={data.firstname}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[8px]">
            <label htmlFor="email">
              Adresse email <span className="text-primary">*</span>
            </label>
            <input
              required
              type="email"
              name="email"
              id="email"
              aria-labelledby="email"
              autoComplete="email"
              onChange={handleChange}
              value={data.email}
            />
          </div>
          <div className="flex flex-col gap-[8px]">
            <label htmlFor="message">
              Message <span className="text-primary">*</span>
            </label>
            <textarea
              required
              name="content"
              id="content"
              rows="5"
              aria-labelledby="content"
              onChange={handleChange}
              value={data.content}
            ></textarea>
          </div>
          <button type="submit" className="w-full bg-primary text-center">
            Envoyer
          </button>
        </form>
      ) : (
        <div className="justify-center items-center flex flex-col gap-4 p-4">
          <div className="loader"></div>
        </div>
      )}
      <PortalModal isOpen={isFinish} onClose={() => setIsFinish(false)}>
        <div className="text-center flex flex-col items-center justify-center gap-4">
          <p className="font-bold text-4xl">Message envoyé avec succès !</p>
          <p className="text-lg">Nous vous contacterons dès que possible.</p>
          <Link
            to="/"
            className="w-fit py-2 px-3 font-bold bg-primary text-center rounded-lg"
          >
            Retour à l'accueil
          </Link>
        </div>
      </PortalModal>
    </>
  );
}
