import { motion } from "framer-motion";
import React from "react";
import DistributorBanner from "../../../assets/img/distrib-banner.png";
import DistributorBannerWebp from "../../../assets/img/distrib-banner.webp";
import GroupBanner from "../../../assets/img/group-banner.png";
import GroupBannerWebp from "../../../assets/img/group-banner.webp";

export default function ImgBanner({ option }) {
  return (
    <section className="img-banner-section bg-white flex justify-center">
      <motion.picture
        initial={{ borderRadius: "2000px", width: "50vw" }}
        whileInView={{ borderRadius: "0", width: "100vw" }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.25 }}
        className="img-banner w-full overflow-hidden"
      >
        <source
          srcSet={
            option === "distrib" ? DistributorBannerWebp : GroupBannerWebp
          }
          type="image/webp"
        />
        <source
          srcSet={option === "distrib" ? DistributorBanner : GroupBanner}
          type="image/png"
        />
        <img
          src={option === "distrib" ? DistributorBanner : GroupBanner}
          alt={
            option === "distrib"
              ? "Un distributeur pizza cook"
              : "Une pizza découpée dans une assiette"
          }
          loading="lazy"
          className="w-full h-full object-cover"
        />
      </motion.picture>
    </section>
  );
}
