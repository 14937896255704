import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import CookiesBanner from "./components/CookiesBanner";
import { ErrorBoundary } from "./components/utils/ErrorBoundary";
import {
  ColorModeContext,
  ToggleContext,
  WidthContext,
} from "./components/utils/Providers";
import Routes from "./routes";

function App() {
  const [color, setColor] = React.useState("black");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [toggle, setToggle] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <HelmetProvider>
        <WidthContext.Provider value={{ width, setWidth }}>
          <ColorModeContext.Provider value={{ color, setColor }}>
            <ToggleContext.Provider value={{ toggle, setToggle }}>
              <ErrorBoundary>
                <Routes />
                <CookiesBanner />
              </ErrorBoundary>
            </ToggleContext.Provider>
          </ColorModeContext.Provider>
        </WidthContext.Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
