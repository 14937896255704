import axiosInstance from "../utils/axiosConfig";

// Envoyer un message général
export const postContactGeneral = async (
  lastname,
  firstname,
  email,
  content
) => {
  try {
    const response = await axiosInstance.post("/messages/contact/general", {
      lastname,
      firstname,
      email,
      content,
    });
    return response.data;
  } catch (error) {
    console.error("Error posting message :", error);
    throw error;
  }
};

// Envoyer un message entreprise
export const postContactEntreprise = async (
  lastname,
  firstname,
  company,
  email,
  nb_pizza,
  date,
  hour,
  content
) => {
  try {
    const response = await axiosInstance.post("/messages/contact/entreprise", {
      lastname,
      firstname,
      company,
      email,
      nb_pizza,
      date,
      hour,
      content,
    });
    return response.data;
  } catch (error) {
    console.error("Error posting message :", error);
    throw error;
  }
};

