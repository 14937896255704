import React from "react";

export default function MainBtn({ value, onClick, name, ariaLabel }) {
  return (
    <button
      className="bg-primary btn-main"
      value={value}
      onClick={onClick}
      name={name}
      aria-label={ariaLabel || value}
    >
      {value}
    </button>
  );
}
