import MainBtn from "../../buttons/MainBtn";

export default function EventStockBanner({
  onClick,
  value,
  title,
  description,
  name,
}) {
  return (
    <section className="event-stock-section banner-section bg-base-100 text-white">
      <div className="event-stock-content banner-content flex flex-col text-center items-center">
        <h2 className="uppercase">{title}</h2>
        <p>{description}</p>
        <MainBtn value={value} onClick={onClick} name={name} />
      </div>
    </section>
  );
}
