import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ColorModeContext, WidthContext } from "../utils/Providers";

export default function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useContext(WidthContext);
  const { color } = useContext(ColorModeContext);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup function to remove the class when component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <div
      className={`burger-menu ${
        color === "white" ? "bg-base-100" : "bg-white"
      } ${isOpen ? "open" : "close"}`}
      onClick={handleClick}
      aria-label="Ouvrir le menu"
      role="button"
    >
      {width < 1024 ? (
        <>
          <div
            className={
              color === "white"
                ? "burger-menu-line bg-white"
                : "burger-menu-line bg-base-100"
            }
          ></div>
          <div
            className={
              color === "white"
                ? "burger-menu-line bg-white"
                : "burger-menu-line bg-base-100"
            }
          ></div>
          <div
            className={
              color === "white"
                ? "burger-menu-line bg-white"
                : "burger-menu-line bg-base-100"
            }
          ></div>
        </>
      ) : (
        <div
          className={`burger-menu-text ${
            color === "white" || color === "beige"
              ? "text-white bg-base-100"
              : "text-base-100 bg-white"
          } flex items-center justify-center`}
        >
          <p className="font-bold uppercase text-[11px]">Menu</p>
        </div>
      )}
      <div className="burger-menu-overlay">
        <div
          className="close-button"
          role="button"
          aria-label="Fermer le menu"
          onClick={handleClick} // Add close button handler to close menu
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <nav role="navigation" aria-label="Menu principal">
          <ul className="burger-menu-items w-full text-white">
            <NavLink to="/" className="burger-menu-li hover:text-primary">
              <li className="w-full">Accueil</li>
            </NavLink>
            <NavLink
              to="/pizza-cook"
              className="burger-menu-li hover:text-primary"
            >
              <li className="w-full">Pizza cook</li>
            </NavLink>
            <NavLink
              to="/notre-carte"
              className="burger-menu-li hover:text-primary"
            >
              <li>La carte</li>
            </NavLink>
            <NavLink
              to="/nos-distributeurs"
              className="burger-menu-li hover:text-primary"
            >
              <li>Nos distributeurs</li>
            </NavLink>
            <NavLink
              to="/contact"
              className="burger-menu-li hover:text-primary"
            >
              <li>Contactez-nous</li>
            </NavLink>
          </ul>
        </nav>
      </div>
    </div>
  );
}
