import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MushroomWhite from "../../assets/img/mushroom-white.png";
import MushroomWhiteWebp from "../../assets/img/mushroom-white.webp";
import PizzaWhite from "../../assets/img/pizza-white.png";
import PizzaWhiteWebp from "../../assets/img/pizza-white.webp";
import SliceWhite from "../../assets/img/slice-white.png";
import SliceWhiteWebp from "../../assets/img/slice-white.webp";
import TomatoWhite from "../../assets/img/tomato-white.png";
import TomatoWhiteWebp from "../../assets/img/tomato-white.webp";
import PizzaCard from "../../components/cards/PizzaCard";
import SearchBar from "../../components/inputs/SearchBar";
import ContactSection from "../../components/sections/ContactSection";
import HeaderInfos from "../../components/sections/HeaderInfos";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import SlidingMessage from "../../components/sections/SlidingMessage";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import { PizzasList } from "../../components/utils/PizzasList";
import {
  ColorModeContext,
  ToggleContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function PizzaMenu() {
  const { width } = useContext(WidthContext);
  const { setColor } = useContext(ColorModeContext);
  const { toggle } = useContext(ToggleContext);
  const [display, setDisplay] = useState("mosaic");
  const [filteredPizzas, setFilteredPizzas] = useState(PizzasList);
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState("");
  const phoneNumber = !toggle ? "02 41 95 40 53" : "02 41 76 10 82";

  const handleClick = (ev) => {
    if (ev.target.name === "contact") {
      navigate("/contact");
    } else if (ev.target.name === "show-menu") {
      document
        .querySelector("#pizzas-section")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const showDetails = (slug) => {
    setDisplay("details");
    navigate("/pizza-details/" + slug);
  };

  const handleDisplay = (ev) => {
    if (ev.target.name === "mosaic") {
      setDisplay("mosaic");
    } else if (ev.target.name === "list") {
      setDisplay("list");
    }
  };

  const handleInput = (ev) => {
    const searchValue = ev.target.value.toLowerCase();
    setSearchInputValue(searchValue);
    const newPizzasList = PizzasList.map((pizzaSection) =>
      pizzaSection.filter((pizza) =>
        pizza.name.toLowerCase().includes(searchValue)
      )
    );
    setFilteredPizzas(newPizzasList);
  };

  useEffect(() => {
    width >= 768 ? setColor("white") : setColor("black");
  }, [width]);

  return (
    <>
      <MetaTagsMaster
        title="La carte"
        description="Nous vous proposons une variété de pizzas délicieuses et savoureuses qui sauront satisfaire tous les goûts."
        keywords="carte, menu, pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <HeaderInfos
        title="La carte"
        description="Nous vous proposons une variété de pizzas délicieuses et savoureuses qui sauront satisfaire tous les goûts."
        option="menu"
        width={width}
        onClick={handleClick}
      />
      {width >= 768 && <SlidingMessage color="beige" />}
      {width >= 1024 && (
        <div className="top-imgs-container flex justify-between w-full">
          <div className="mushroom-white-container">
            <picture className="mushroom-white">
              <source srcSet={MushroomWhiteWebp} type="image/webp" />
              <source srcSet={MushroomWhite} type="image/png" />
              <motion.img
                initial={{
                  x: width >= 768 ? -100 : -25,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={MushroomWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
          <div className="pizza-white-container">
            <picture className="pizza-white">
              <source srcSet={PizzaWhiteWebp} type="image/webp" />
              <source srcSet={PizzaWhite} type="image/png" />
              <motion.img
                initial={{
                  rotate: 0,
                  scale: 0,
                  opacity: 0,
                }}
                whileInView={{
                  rotate: 360,
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={PizzaWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      )}
      <section
        className={
          filteredPizzas[0].length > 0
            ? "normal-pizza-section flex flex-col"
            : "normal-pizza-section-bis flex flex-col"
        }
        id="pizzas-section"
      >
        <h2 className="sr-only">Liste des pizzas</h2>
        <SearchBar
          onClick={handleDisplay}
          display={display}
          onInput={handleInput}
          width={width}
        />
        <div
          className={
            display === "mosaic"
              ? "mosaic-pizza-container grid grid-cols-2 gap-4"
              : "list-pizza-container flex flex-col "
          }
        >
          {filteredPizzas[0].map((pizza, i) => (
            <PizzaCard
              key={i}
              pizza={pizza}
              display={display}
              onClick={() => showDetails(pizza.slug)}
            />
          ))}
        </div>
        {width < 768 && (
          <div className="fixed-banner flex justify-between items-center fixed bottom-10 left-0 right-0 w-full bg-primary h-[72px] px-[18px] text-white">
            <p>Commander une pizza !</p>
            <a
              href={`tel:${phoneNumber}`}
              aria-label="Appeler le numéro de téléphone"
            >
              <div className="fixed-banner-svg">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#1D1D1B" />
                  <path
                    d="M23.3592 22.906L22.9295 23.359C22.9295 23.359 21.9066 24.435 19.1158 21.497C16.325 18.559 17.3478 17.483 17.3478 17.483L17.6179 17.197C18.2857 16.495 18.3489 15.367 17.7662 14.543L16.5762 12.86C15.8547 11.84 14.4616 11.705 13.6352 12.575L12.1525 14.135C11.7435 14.567 11.4696 15.125 11.5027 15.745C11.5877 17.332 12.2658 20.745 16.0473 24.727C20.0584 28.949 23.8219 29.117 25.3604 28.965C25.8478 28.917 26.2709 28.655 26.6118 28.295L27.9529 26.883C28.8596 25.93 28.6046 24.295 27.4448 23.628L25.6409 22.589C24.8797 22.151 23.9542 22.28 23.3592 22.906Z"
                    fill="white"
                  />
                </svg>
              </div>
            </a>
          </div>
        )}
      </section>
      {width >= 1024 && searchInputValue === "" ? (
        <div className="bottom-imgs-container flex justify-between w-full">
          <div className="slice-white-container">
            <picture className="slice-white">
              <source srcSet={SliceWhiteWebp} type="image/webp" />
              <source srcSet={SliceWhite} type="image/png" />
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0.5,
                }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={SliceWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
          <div className="tomato-white-container place-self-end">
            <picture className="tomato-white">
              <source srcSet={TomatoWhiteWebp} type="image/webp" />
              <source srcSet={TomatoWhite} type="image/png" />
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0.5,
                }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={TomatoWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      ) : (
        width >= 1024 &&
        filteredPizzas[0].length > 0 &&
        filteredPizzas[1].length > 0 && (
          <div className="bottom-imgs-container flex justify-end w-full pt-14">
            <div className="tomato-white-container place-self-end">
              <picture className="tomato-white">
                <source srcSet={TomatoWhiteWebp} type="image/webp" />
                <source srcSet={TomatoWhite} type="image/png" />
                <motion.img
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                  }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={TomatoWhite}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        )
      )}
      {filteredPizzas[1].length > 0 && (
        <section className="kids-pizza-section flex flex-col">
          <div className="loulous-title grid">
            <h2 className="text-white uppercase">Pour les loulous</h2>
            <div className="bg-primary surprise-container flex justify-end items-center">
              <p className="text-white uppercase pr-1">+ une surprise</p>
            </div>
          </div>
          <div
            className={
              display === "mosaic"
                ? "mosaic-pizza-container grid grid-cols-2 gap-4"
                : "list-pizza-container flex flex-col "
            }
          >
            {filteredPizzas[1].map((pizza, i) => (
              <PizzaCard
                key={i}
                pizza={pizza}
                display={display}
                onClick={() => showDetails(pizza.slug)}
              />
            ))}
          </div>
        </section>
      )}
      {filteredPizzas[0].length === 0 && filteredPizzas[1].length === 0 && (
        <div className="no-pizzas">
          <h2 className="text-white uppercase">
            Il n'y a pas de pizzas correspondant à votre recherche
          </h2>
        </div>
      )}
      <PizzeriasSection width={width} />
      <ContactSection onClick={handleClick} />
    </>
  );
}
