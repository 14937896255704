import MainLayout from "../components/layouts/MainLayout";
import CompanyDelivery from "../pages/public/CompanyDelivery";
import Distributors from "../pages/public/Distributors";
import Landing from "../pages/public/Landing";
import PizzaCook from "../pages/public/PizzaCook";
import PizzaDetails from "../pages/public/PizzaDetails";
import PizzaMenu from "../pages/public/PizzaMenu";

export const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/pizza-cook",
      element: <PizzaCook />,
    },
    {
      path: "/notre-carte",
      element: <PizzaMenu />,
    },
    {
      path: "/pizza-details/:slug",
      element: <PizzaDetails />,
    },
    {
      path: "/nos-distributeurs",
      element: <Distributors />,
    },
    {
      path: "/livraison",
      element: <CompanyDelivery />,
    },
  ],
};
