import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderInfos from "../../components/sections/HeaderInfos";
import PizzaCarrouselSection from "../../components/sections/PizzaCarrouselSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import EventStockBanner from "../../components/sections/banners/EventStockBanner";
import GroupDistribBanner from "../../components/sections/banners/GroupDistribBanner";
import ImgBanner from "../../components/sections/banners/ImgBanner";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function CompanyDelivery() {
  const { width } = useContext(WidthContext);
  const { setColor } = useContext(ColorModeContext);
  const navigate = useNavigate();

  const handleClick = (ev) => {
    if (ev.target.name === "menu") {
      navigate("/notre-carte");
    }
    if (ev.target.name === "request") {
      navigate("/contact");
    }
  };

  useEffect(() => {
    setColor("white");
  }, []);

  return (
    <>
      <MetaTagsMaster
        title="Groupes & entreprises"
        description="Réunion de famille, repas entre amis, fêtes d'entreprise, etc. Pizza Cook s'adapte à vos envies et vous propose la livraison."
        keywords="livraison, groupes, entreprises, repas, pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <HeaderInfos
        title="Groupes & entreprises"
        description="Réunion de famille, repas entre amis, fêtes d'entreprise, etc. Pizza Cook s'adapte à vos envies et vous propose la livraison."
        option="group"
        width={width}
      />
      <GroupDistribBanner
        title="Livraison possible (sous conditions)"
        firstDescription="Pizza Cook à Saint-Sylvain-d'Anjou propose aux entreprises et salariés un service de livraison le midi."
        secondDescription="Nous garantissons des pizzas chaudes, pré-coupées et livrées directement en entreprise."
      />
      <PizzaCarrouselSection onClick={handleClick} />
      <EventStockBanner
        title="Vous avez un événement ?"
        description="Faites une demande en ligne, nous serons ravis de participer à votre événement."
        value="Faire une demande en ligne"
        name="request"
        onClick={handleClick}
      />
      <ImgBanner option="group" />
      <PizzeriasSection width={width} />
    </>
  );
}
