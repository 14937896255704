import {motion} from "framer-motion";
import {useContext} from "react";
import CheeseGrey from "../../assets/img/cheese-grey.png";
import CheeseGreyWebp from "../../assets/img/cheese-grey.webp";
import Line from "../../assets/img/line.png";
import PizzaLettersGrey from "../../assets/img/pizza-letters-grey.png";
import PizzaLettersGreyWebp from "../../assets/img/pizza-letters-grey.webp";
import Map from "../Map";
import ToggleBtn from "../buttons/ToggleBtn";
import {ToggleContext} from "../utils/Providers";

export default function PizzeriasSection({width}) {
  const {toggle} = useContext(ToggleContext);

  const phoneNumber = !toggle ? "02 41 95 40 53" : "02 41 76 10 82";
  const mapsLink = !toggle
    ? "https://www.google.fr/maps/place/Pizza+Cook/@47.5187709,-0.4752708,17z/data=!3m1!4b1!4m6!3m5!1s0x480877484944a299:0xad72720442fb138d!8m2!3d47.5187673!4d-0.4726959!16s%2Fg%2F11ckqt05ty?entry=ttu"
    : "https://www.google.fr/maps/place/Pizza+Cook+-+Seiches-sur-le-Loir/@47.5735219,-0.3579983,17z/data=!3m1!4b1!4m6!3m5!1s0x480872e8a550039b:0x95f928cdfdad829a!8m2!3d47.5735183!4d-0.3554234!16s%2Fg%2F1tlc7f_d?entry=ttu";

  return (
    <section className="pizzerias-section bg-white text-base-100 flex flex-col items-center">
      {width >= 768 && (
        <div className="flex justify-end w-full">
          <picture className="cheese-grey">
            <source srcSet={CheeseGreyWebp} type="image/webp"/>
            <source srcSet={CheeseGrey} type="image/png"/>
            <motion.img
              initial={{
                x: 100,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{once: true, amount: 0.3}}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={CheeseGrey}
              alt=""
              loading="lazy"
            />
          </picture>
        </div>
      )}
      <h2 className="uppercase">Les pizzerias</h2>
      <div className="flex items-center toggle-container">
        <h3 className="uppercase text-right">Saint-Sylvain-D'Anjou</h3>
        <ToggleBtn
          className="toggle toggle-input [--tglbg:white] hover:bg-primary bg-primary border-primary"
          ariaLabel={!toggle ? "Saint-Sylvain-D'Anjou" : "Seiches-Sur-Le-Loir"}
        />
        <h3 className="uppercase text-left">Seiches-Sur-Le-Loir</h3>
      </div>
      <div className="flex items-center icons-container">
        <a
          href={`tel:${phoneNumber}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Téléphone"
        >
          <div className="icon-container">
            <motion.svg
              initial={{
                opacity: 0,
                scale: 0.5,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{once: true, amount: 0.3}}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              width="45"
              height="44"
              viewBox="0 0 45 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="phone-icon"
              loading="lazy"
            >
              <path
                d="M35.3747 27.9769L30.718 27.4452C30.1705 27.3809 29.6155 27.4415 29.0947 27.6225C28.574 27.8035 28.101 28.1002 27.7114 28.4902L24.338 31.8636C19.1336 29.2166 14.9033 24.9864 12.2564 19.7819L15.648 16.3902C16.4364 15.6019 16.8214 14.5019 16.693 13.3836L16.1614 8.76356C16.0574 7.86921 15.6282 7.04432 14.9555 6.44594C14.2827 5.84756 13.4134 5.51749 12.513 5.51856H9.34137C7.2697 5.51856 5.54637 7.24189 5.6747 9.31356C6.64637 24.9702 19.168 37.4736 34.8064 38.4452C36.878 38.5736 38.6014 36.8502 38.6014 34.7786V31.6069C38.6197 29.7552 37.2264 28.1969 35.3747 27.9769Z"
                fill="#BA1817"
              />
            </motion.svg>
            {width >= 768 && (
              <>
                <h4 className="uppercase">
                  <span style={{animationDelay: '0ms'}}>T</span>
                  <span style={{animationDelay: '50ms'}}>é</span>
                  <span style={{animationDelay: '100ms'}}>l</span>
                  <span style={{animationDelay: '150ms'}}>é</span>
                  <span style={{animationDelay: '200ms'}}>p</span>
                  <span style={{animationDelay: '250ms'}}>h</span>
                  <span style={{animationDelay: '300ms'}}>o</span>
                  <span style={{animationDelay: '350ms'}}>n</span>
                  <span style={{animationDelay: '400ms'}}>e</span>
                </h4>
                <p>{phoneNumber}</p>
              </>
            )}
          </div>
        </a>
        <a
          href={mapsLink}
          target="_blank"
          rel="noreferrer"
          aria-label="Adresse"
        >
          <div className="icon-container">
            <motion.svg
              initial={{
                opacity: 0,
                scale: 0.5,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{once: true, amount: 0.3}}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              className="map-icon"
              width="53"
              height="52"
              viewBox="0 0 53 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              loading="lazy"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.2731 45.5002L32.9127 16.9219L8.78906 19.1145V38.5669C8.78906 40.9935 8.78906 42.2069 9.2614 43.1342C9.67684 43.9495 10.3397 44.6124 11.1551 45.0279C12.0824 45.5002 13.2957 45.5002 15.7224 45.5002H38.2731ZM29.3724 32.5652C29.3724 37.2409 24.7357 40.5255 22.7142 41.7367C22.435 41.9051 22.1151 41.994 21.7891 41.994C21.463 41.994 21.1431 41.9051 20.8639 41.7367C18.8424 40.5255 14.2057 37.2409 14.2057 32.563C14.2057 27.9762 17.8804 24.9169 21.7891 24.9169C25.8342 24.9169 29.3724 27.9762 29.3724 32.5652Z"
                fill="#BA1817"
              />
              <path
                d="M9.2614 8.866C8.78906 9.79333 8.78906 11.0067 8.78906 13.4333V14.7637L47.7674 11.219C47.7262 10.1617 47.6157 9.45533 47.3167 8.866C46.9013 8.05067 46.2384 7.38778 45.4231 6.97233C44.4957 6.5 43.2824 6.5 40.8557 6.5H15.7224C13.2957 6.5 12.0824 6.5 11.1551 6.97233C10.3397 7.38778 9.67684 8.05067 9.2614 8.866ZM47.7891 15.5697L37.2482 16.5273L42.6779 45.4892C43.9606 45.461 44.7644 45.3635 45.4231 45.0277C46.2384 44.6122 46.9013 43.9493 47.3167 43.134C47.7891 42.2067 47.7891 40.9933 47.7891 38.5667V15.5697Z"
                fill="#892301"
                fill-opacity="0.25"
              />
              <path
                d="M21.7878 34.6663C22.9844 34.6663 23.9544 33.6963 23.9544 32.4997C23.9544 31.3031 22.9844 30.333 21.7878 30.333C20.5911 30.333 19.6211 31.3031 19.6211 32.4997C19.6211 33.6963 20.5911 34.6663 21.7878 34.6663Z"
                fill="#BA1817"
              />
            </motion.svg>
            {width >= 768 && (
              <>
                <h4 className="uppercase">
                  <span style={{animationDelay: '0ms'}}>A</span>
                  <span style={{animationDelay: '50ms'}}>d</span>
                  <span style={{animationDelay: '100ms'}}>r</span>
                  <span style={{animationDelay: '150ms'}}>e</span>
                  <span style={{animationDelay: '200ms'}}>s</span>
                  <span style={{animationDelay: '250ms'}}>s</span>
                  <span style={{animationDelay: '300ms'}}>e</span>
                </h4>
                <div className="flex flex-col items-center">
                  {!toggle ? (
                    <p>16 rue Emmanuel Voisin</p>
                  ) : (
                    <p>58 rue Nationale</p>
                  )}
                  {!toggle ? (
                    <p>49480 Saint-Sylvain-D'Anjou</p>
                  ) : (
                    <p>49140 Seiches-Sur-Le-Loir</p>
                  )}
                </div>
              </>
            )}
          </div>
        </a>
        <a
          href="mailto:contact@pizzacook.fr"
          target="_blank"
          rel="noreferrer"
          aria-label="Email"
        >
          <div className="icon-container">
            <motion.svg
              initial={{
                opacity: 0,
                scale: 0.5,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{once: true, amount: 0.3}}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              className="mail-icon"
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              loading="lazy"
            >
              <path
                d="M43.3219 17.6948V34.7996C43.3219 36.3909 42.6897 37.917 41.5645 39.0423C40.4393 40.1675 38.9132 40.7996 37.3219 40.7996H10.9219C9.33058 40.7996 7.80445 40.1675 6.67923 39.0423C5.55402 37.917 4.92188 36.3909 4.92188 34.7996V17.6948L23.5123 28.634C23.697 28.7429 23.9075 28.8004 24.1219 28.8004C24.3363 28.8004 24.5468 28.7429 24.7315 28.634L43.3219 17.6948ZM37.3219 9.59961C38.7982 9.59938 40.2228 10.1435 41.3232 11.1278C42.4235 12.1121 43.1223 13.4675 43.2859 14.9348L24.1219 26.2076L4.95788 14.9348C5.12145 13.4675 5.82025 12.1121 6.92058 11.1278C8.02091 10.1435 9.44552 9.59938 10.9219 9.59961H37.3219Z"
                fill="#BA1817"
              />
            </motion.svg>
            {width >= 768 && (
              <>
                <h4 className="uppercase">
                  <span style={{animationDelay: '0ms'}}>E</span>
                  <span style={{animationDelay: '50ms'}}>m</span>
                  <span style={{animationDelay: '100ms'}}>a</span>
                  <span style={{animationDelay: '150ms'}}>i</span>
                  <span style={{animationDelay: '200ms'}}>l</span>
                </h4>
                <p>contact@pizzacook.fr</p>
              </>
            )}
          </div>
        </a>
      </div>
      <div className="w-full flex flex-col map-time-container">
        <Map toggle={toggle} aria-label="Carte"/>
        <div className="bg-primary flex flex-col time-container items-center">
          <div className="time-content-container flex flex-col">
            {!toggle && (
              <>
                <div>
                  <div className="flex items-center text-white time-content">
                    <p className="uppercase">Midi</p>
                    <img
                      src={Line}
                      alt=""
                      className="time-content-img"
                      loading="lazy"
                    />{" "}
                    {/* TODO: fix image to svg? */}
                    <p>11h30/14h00</p>
                  </div>
                  <div className="flex items-center text-white time-content">
                    <p className="uppercase">Soir</p>
                    <img
                      src={Line}
                      alt=""
                      className="time-content-img"
                      loading="lazy"
                    />{" "}
                    {/* TODO: fix image to svg? */}
                    <p>18h00/21h30</p>
                  </div>
                  <p className="text-white italic date">du lundi au mercredi</p>
                </div>
                <div>
                  <div className="flex items-center text-white time-content">
                    <p className="uppercase">Midi</p>
                    <img
                      src={Line}
                      alt=""
                      className="time-content-img"
                      loading="lazy"
                    />{" "}
                    {/* TODO: fix image to svg? */}
                    <p>11h30/14h00</p>
                  </div>
                  <div className="flex items-center text-white time-content">
                    <p className="uppercase">Soir</p>
                    <img
                      src={Line}
                      alt=""
                      className="time-content-img"
                      loading="lazy"
                    />{" "}
                    {/* TODO: fix image to svg? */}
                    <p>18h00/22h00</p>
                  </div>
                  <p className="text-white italic date">jeudi et dimanche</p>
                </div>
                <div>
                  <div className="flex items-center text-white time-content">
                    <p className="uppercase">Soir</p>
                    <img
                      src={Line}
                      alt=""
                      className="time-content-img"
                      loading="lazy"
                    />{" "}
                    {/* TODO: fix image to svg? */}
                    <p>18h00/21h30</p>
                  </div>
                  <p className="text-white italic date">vendredi et samedi</p>
                </div>
              </>
            )}
            {toggle && (
              <>
                <div>
                  <div className="flex items-center text-white time-content">
                    <p className="uppercase">Soir</p>
                    <img
                      src={Line}
                      alt=""
                      className="time-content-img"
                      loading="lazy"
                    />{" "}
                    {/* TODO: fix image to svg? */}
                    <p>18h00/21h30</p>
                  </div>
                  <p className="text-white italic date">du lundi au dimanche</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end z-10">
        <picture className="pizza-letters-grey">
          <source srcSet={PizzaLettersGreyWebp} type="image/webp"/>
          <source srcSet={PizzaLettersGrey} type="image/png"/>
          <motion.img
            initial={{
              opacity: 0,
              scale: 0,
            }}
            whileInView={{
              scale: 1,
              opacity: 1,
            }}
            viewport={{once: true, amount: 0.3}}
            transition={{
              duration: 2,
              ease: "backInOut",
            }}
            src={PizzaLettersGrey}
            alt=""
            loading="lazy"
          />
        </picture>
      </div>
    </section>
  );
}
