import React, { useState } from "react";
import { Link } from "react-router-dom";
import { postContactEntreprise } from "../../api/Message";
import PortalModal from "../PortalModal";

export default function EventForm() {
  const [step, setStep] = useState(1);
  const maxStep = 3;
  const [data, setData] = useState({
    lastname: "",
    firstname: "",
    company: "",
    email: "",
    nb_pizza: "",
    date: "",
    hour: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step < maxStep) {
      setStep(step + 1);
    } else {
      setIsLoading(true);

      try {
        await postContactEntreprise(
          data.lastname,
          data.firstname,
          data.company,
          data.email,
          data.nb_pizza,
          data.date,
          data.hour,
          data.content
        );
        setIsFinish(true);
        setData({
          lastname: "",
          firstname: "",
          company: "",
          email: "",
          nb_pizza: "",
          date: "",
          hour: "",
          content: "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          {step === 1 && (
            <div className="event-form-container flex flex-col">
              <h2 className="uppercase text-center">Informations</h2>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col"
                autoComplete="on"
              >
                <div className="flex flex-col gap-[8px]">
                  <label htmlFor="nbPizzas">Nombre de pizza</label>
                  <input
                    required
                    type="number"
                    name="nb_pizza"
                    id="nb_pizza"
                    aria-labelledby="nb_pizza"
                    autoComplete="nb_pizza"
                    onChange={handleChange}
                    value={data.nb_pizza}
                  />
                </div>
                <div className="flex gap-[24px] name-container">
                  <div className="flex flex-col gap-[8px] w-full">
                    <label htmlFor="eventDate">Date de l'événement</label>
                    <input
                      required
                      type="date"
                      name="date"
                      id="date"
                      aria-labelledby="date"
                      autoComplete="date"
                      onChange={handleChange}
                      value={data.date}
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full">
                    <label htmlFor="eventTime">Heure de l'événement</label>
                    <input
                      required
                      type="time"
                      name="hour"
                      id="hour"
                      aria-labelledby="hour"
                      autoComplete="hour"
                      onChange={handleChange}
                      value={data.hour}
                    />
                  </div>
                </div>
                <button type="submit" className="w-full bg-primary text-center">
                  Étape suivante
                </button>
              </form>
            </div>
          )}
          {step === 2 && (
            <div className="event-form-container flex flex-col">
              <h2 className="uppercase text-center">Votre message</h2>
              <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col">
                <div className="flex flex-col gap-[8px]">
                  <label htmlFor="message">Message</label>
                  <textarea
                    required
                    type="text"
                    name="content"
                    id="content"
                    aria-labelledby="content"
                    autoComplete="content"
                    onChange={handleChange}
                    value={data.content}
                  ></textarea>
                </div>
                <button type="submit" className="w-full bg-primary text-center">
                  Étape suivante
                </button>
              </form>
            </div>
          )}
          {step === 3 && (
            <div className="event-form-container flex flex-col">
              <h2 className="uppercase text-center">Votre identité</h2>
              <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col">
                <div className="flex gap-[24px] name-container">
                  <div className="flex flex-col gap-[8px] w-full">
                    <label htmlFor="lastname">Nom</label>
                    <input
                      required
                      type="text"
                      name="lastname"
                      id="lastname"
                      aria-labelledby="lastname"
                      autoComplete="lastname"
                      onChange={handleChange}
                      value={data.lastname}
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full">
                    <label htmlFor="firstname">Prénom</label>
                    <input
                      required
                      type="text"
                      name="firstname"
                      id="firstname"
                      aria-labelledby="firstname"
                      autoComplete="firstname"
                      onChange={handleChange}
                      value={data.firstname}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[8px]">
                  <label htmlFor="company">Société</label>
                  <input
                    required
                    type="text"
                    name="company"
                    id="company"
                    aria-labelledby="company"
                    autoComplete="company"
                    onChange={handleChange}
                    value={data.company}
                  />
                </div>
                <div className="flex flex-col gap-[8px]">
                  <label htmlFor="email">Email</label>
                  <input
                    required
                    type="email"
                    name="email"
                    id="email"
                    aria-labelledby="email"
                    autoComplete="email"
                    onChange={handleChange}
                    value={data.email}
                  />
                </div>
                <button type="submit" className="w-full bg-primary text-center">
                  Envoyer
                </button>
              </form>
            </div>
          )}
        </>
      ) : (
        <div className="justify-center items-center flex flex-col gap-4 p-4">
          <div className="loader"></div>
        </div>
      )}
      <PortalModal isOpen={isFinish} onClose={() => setIsFinish(false)}>
        <div className="text-center flex flex-col items-center justify-center gap-4">
          <p className="font-bold text-4xl">Message envoyé avec succès !</p>
          <p className="text-lg">Nous vous contacterons dès que possible.</p>
          <Link
            to="/"
            className="w-fit py-2 px-3 font-bold bg-primary text-center rounded-lg"
          >
            Retour à l'accueil
          </Link>
        </div>
      </PortalModal>
    </>
  );
}
