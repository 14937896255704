export default function GroupDistribBanner({
  title,
  firstDescription,
  secondDescription,
}) {
  return (
    <section className="group-distrib-section banner-section bg-white text-base-100">
      <div className="group-distrib-content banner-content flex flex-col text-center gap-4">
        <h2 className="uppercase">{title}</h2>
        <p>{firstDescription}</p>
        <p>{secondDescription}</p>
      </div>
    </section>
  );
}
